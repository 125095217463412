var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row page-title-header"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"page-header"},[_c('b-breadcrumb',{staticClass:"m-0"},[_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'DashboardHome',
            }}},[_c('i',{staticClass:"fa fa-home"})]),_c('b-breadcrumb-item',{attrs:{"to":{
              name: 'ChatroomIndex',
            }}},[_vm._v("聊天室管理")]),_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v("聊天室列表")])],1),(_vm.newestNotification && _vm.$permissions.has(_vm.$permissions.consts.MY_NOTIFICATION_LATEST))?_c('div',{staticClass:"ml-auto d-flex align-items-center"},[_c('router-link',{attrs:{"to":{ name: 'BranchNotificationDetail', params: { notification_id: _vm.newestNotification.id } }}},[_c('i',{class:{
                'fa fa-envelope-o text-danger': !_vm.newestNotification.is_read,
                'fa fa-envelope-open-o text-black': _vm.newestNotification.is_read,
              }}),_c('a',{staticClass:"ml-2 text-black",class:{
              'text-danger': !_vm.newestNotification.is_read
            }},[_vm._v(" "+_vm._s(_vm.newestNotification.title)+" : "+_vm._s(_vm.format(new Date(_vm.newestNotification.publish_at), 'MM/dd HH:mm'))+" ")])])],1):_vm._e()],1)])]),_c('b-card',[_c('div',{staticClass:"row d-flex mb-2 mb-xl-2 justify-content-between"},[_c('div',{staticClass:"col-6 d-flex align-items-center"},[_c('h4',{staticClass:"mb-2 mb-xl-0 mr-2 font-weight-bold"},[_vm._v("聊天室列表")])]),_c('div',{staticClass:"col-6 d-flex justify-content-end"},[_c('b-input-group',{staticClass:"ml-0 ml-xl-4"},[_c('b-form-input',{attrs:{"placeholder":"搜尋"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetchChatrooms(1)}},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1),(_vm.checkPermission([_vm.consts.CHATROOM_LIST_CREATE]))?_c('b-button',{staticClass:"mb-2 mb-xl-0 ml-2 py-2",attrs:{"variant":"primary"},on:{"click":_vm.handleCreate}},[_c('i',{staticClass:"fa fa-plus",attrs:{"aria-hidden":"true"}}),_vm._v("新增聊天室")]):_vm._e()],1)]),_c('b-table',{attrs:{"striped":"","hover":"","responsive":"","items":_vm.chatrooms,"fields":_vm.fields,"busy":_vm.isLoading},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"cell(created_at)",fn:function(data){return [_vm._v(" "+_vm._s(_vm.format(new Date(data.item.created_at), 'yyyy/MM/dd HH:mm'))+" ")]}},{key:"cell(closed_at)",fn:function(data){return [(data.item.closed_at)?_c('div',[_vm._v(" "+_vm._s(_vm.format(new Date(data.item.closed_at), 'yyyy/MM/dd HH:mm'))+" ")]):_vm._e()]}},{key:"cell(actions)",fn:function(data){return [(_vm.checkPermission([_vm.consts.CHATROOM_LIST_MODIFY]))?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-warning","to":{
            name: 'ChatroomEdit',
            params: {
              chatroomId: data.item.id,
            },
          }}},[_vm._v("編輯")]):_vm._e(),(_vm.checkPermission([_vm.consts.CHATROOM_LIST_VIEW]))?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-primary","to":{
            name: 'ChatroomSenderList',
            params: {
              chatroomId: data.item.id,
            },
          }}},[_vm._v("查看聊天室成員")]):_vm._e(),(_vm.checkPermission([_vm.consts.CHATROOM_LIST_VIEW_MSG]))?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"inverse-info","to":{
            name: 'ChatroomMessageList',
            params: {
              chatroomId: data.item.id,
            },
          }}},[_vm._v("查看聊天內容")]):_vm._e(),(_vm.checkPermission([_vm.consts.CHATROOM_LIST_MODIFY]))?_c('b-button',{staticClass:"ml-2",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.handleDelete(data.item)}}},[_vm._v("刪除")]):_vm._e()]}}])}),_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"margin-top":"80px"}},[_c('b-pagination',{staticClass:"separated",attrs:{"total-rows":_vm.total,"per-page":_vm.perPage,"aria-controls":"chatroomList"},on:{"change":function (page) { return _vm.fetchChatrooms(page); }},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }