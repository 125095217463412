<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item
              :to="{
                name: 'ChatroomIndex',
              }"
              >聊天室管理</b-breadcrumb-item
            >
            <b-breadcrumb-item active>聊天室列表</b-breadcrumb-item>
          </b-breadcrumb>
          <div class="ml-auto d-flex align-items-center" v-if="newestNotification && $permissions.has($permissions.consts.MY_NOTIFICATION_LATEST)">
            <router-link
              :to="{ name: 'BranchNotificationDetail', params: { notification_id: newestNotification.id } }"
            >
              <i :class="{
                  'fa fa-envelope-o text-danger': !newestNotification.is_read,
                  'fa fa-envelope-open-o text-black': newestNotification.is_read,
                }"/>
              <a class="ml-2 text-black" :class="{
                'text-danger': !newestNotification.is_read
              }">
                {{ newestNotification.title }} : {{ format(new Date(newestNotification.publish_at), 'MM/dd HH:mm') }}
              </a>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-2 mb-xl-2 justify-content-between">
        <div class="col-6 d-flex align-items-center">
          <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">聊天室列表</h4>
        </div>
        <div class="col-6 d-flex justify-content-end">
          <b-input-group class="ml-0 ml-xl-4">
            <b-form-input
              v-model="keyword"
              v-on:keyup.enter="fetchChatrooms(1)"
              placeholder="搜尋"
            ></b-form-input>
          </b-input-group>
          <b-button
            v-if="checkPermission([consts.CHATROOM_LIST_CREATE])"
            class="mb-2 mb-xl-0 ml-2 py-2"
            variant="primary"
            @click="handleCreate"
            ><i class="fa fa-plus" aria-hidden="true"></i>新增聊天室</b-button
          >
        </div>
      </div>

      <b-table
        striped
        hover
        responsive
        :items="chatrooms"
        :fields="fields"
        :busy="isLoading"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <!-- <template #cell(link)="data">
          <b-link :href="data.item.link" target="_blank">{{
            data.item.link
          }}</b-link>
        </template> -->
        <template #cell(created_at)="data">
          {{ format(new Date(data.item.created_at), 'yyyy/MM/dd HH:mm') }}
        </template>
        <template #cell(closed_at)="data">
          <div v-if="data.item.closed_at">
          {{ format(new Date(data.item.closed_at), 'yyyy/MM/dd HH:mm') }}
          </div>
        </template>
        <template #cell(actions)="data">
          <b-button
            v-if="checkPermission([consts.CHATROOM_LIST_MODIFY])"
            class="ml-2"
            variant="inverse-warning"
            :to="{
              name: 'ChatroomEdit',
              params: {
                chatroomId: data.item.id,
              },
            }"
            >編輯</b-button
          >

          <b-button
            v-if="checkPermission([consts.CHATROOM_LIST_VIEW])"
            class="ml-2"
            variant="inverse-primary"
            :to="{
              name: 'ChatroomSenderList',
              params: {
                chatroomId: data.item.id,
              },
            }"
            >查看聊天室成員</b-button
          >
          <b-button
            v-if="checkPermission([consts.CHATROOM_LIST_VIEW_MSG])"
            class="ml-2"
            variant="inverse-info"
            :to="{
              name: 'ChatroomMessageList',
              params: {
                chatroomId: data.item.id,
              },
            }"
            >查看聊天內容</b-button
          >

          <b-button
            v-if="checkPermission([consts.CHATROOM_LIST_MODIFY])"
            class="ml-2"
            variant="danger"
            @click="handleDelete(data.item)"
            >刪除</b-button
          >
        </template>
      </b-table>

      <div class="d-flex justify-content-center" style="margin-top: 80px">
        <b-pagination
          class="separated"
          v-model="currentPage"
          :total-rows="total"
          :per-page="perPage"
          aria-controls="chatroomList"
          @change="(page) => fetchChatrooms(page)"
        ></b-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import chatroomApi from "../../../apis/chatroomManagement";
import * as consts from "@/consts";
import PermissionChecker from "@/utils/PermissionChecker";
import format from "date-fns/format";

export default {
  data() {
    return {
      consts,
      isLoading: false,
      chatrooms: [],
      fields: [
        {
          key: "title",
          label: "標題",
        },
        {
          key: "status",
          label: "狀態",
        },
        {
          key: "is_enabled",
          label: "是否啟用",
        },
        {
          key: "senderCount",
          label: "成員人數",
        },
        // {
        //   key: "link",
        //   label: "聊天室網址",
        // },
        {
          key: "created_at",
          label: "建立時間",
        },
        {
          key: "closed_at",
          label: "停用時間",
        },
        {
          key: "category",
          label: "聊天室類型",
        },
        {
          key: "actions",
          label: "管理",
        },
      ],
      perPage: 15,
      currentPage: 1,
      total: 0,
      keyword: null,
    };
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
      newestNotification: (state) => state.newestNotification,
    }),
  },
  mounted() {
    this.fetchChatrooms();
  },
  methods: {
    format,
    async fetchChatrooms(page = 1) {
      try {
        this.isLoading = true;
        const payload = { keyword: this.keyword }
        const { data } = await chatroomApi.getChatrooms(payload);
        this.total = data.data.length;
        const start = (page - 1) * this.perPage;
        const end = page * this.perPage;
        this.chatrooms = Array.isArray(data.data)
          ? data.data.slice(start, end).map((chatroomData) => ({
              senderCount: Array.isArray(chatroomData.senders)
                ? chatroomData.senders.length
                : 0,
              ...chatroomData,
            }))
          : [];
      } catch (error) {
        console.log("");
      }
      this.isLoading = false;
    },
    async handleDelete(chatroom) {
      this.$swal
        .fire({
          type: "warning",
          title: "你確定要刪除嗎？",
          html: `
            <div class="d-block">
              <div class="my-3">
                <div>聊天室名稱：${chatroom.title}</div>
              </div>
              <div>刪除後，相關資料將無法回復，<br/>請您確認！</div>
            </div>`,
          confirmButtonText: "確定刪除",
          cancelButtonText: "不刪除",
          showCancelButton: true,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#3085d6",
          reverseButtons: true,
        })
        .then(async (result) => {
          if (result.value) {
            try {
              this.isLoading = true;
              await chatroomApi.deleteChatroom(chatroom.id);
              await this.fetchChatrooms();
            } catch (error) {
              console.log("");
            }
            this.isLoading = false;
          }
        });
    },
    handleCreate() {
      this.$router.push({
        name: "ChatroomCreate",
      });
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    }
  },
};
</script>
